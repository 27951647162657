import * as React from 'react';
import Layout from '../components/layout';
import { Helmet } from 'react-helmet';
import { motion } from 'framer-motion';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';

import Partners from '../components/partners';

// import {} from '../components/styles/about.css';
import gnk_logo_text from '../assets/svg/LOGO_TEKST.svg';

import bjornar from '../assets/portraits/portrett_bjornar_2.jpg';
import lars from '../assets/portraits/portrett_lars_2.jpg';

const transition_short = { duration: 0.2, ease: [0.6, 0.01, -0.05, 0.9] };
const transition_long = { duration: 0.6, ease: [0.6, 0.01, -0.05, 0.9] };

const About = ({ data }) => {
	return (
		<Layout pageTitle="Om Oss" background={true}>
			<Helmet
				bodyAttributes={{
					class: 'light-green-bg',
				}}
			/>

			<style jsx>{`
				.process {
					color: #042825;
				}

				.process__title {
					margin-bottom: calc(120px * var(--scale));
				}

				.process__title h1 {
					font-weight: 400;
					letter-spacing: -1px;
					line-height: 1.1;
					max-width: calc(600px * var(--scale));
				}

				@media (min-width: 768px) and(max-width: 991px) {
					.process__title h1 {
						max-width: calc(640px * var(--scale));
					}
				}

				@media (max-width: 767px) {
					.process__title h1 {
						font-size: 3rem;
					}
				}

				.process__grid {
					margin: 0 -20px;
					padding: 0;
					list-style: none;
					display: flex;
					flex-wrap: wrap;
				}

				.process__grid li {
					margin-bottom: calc(100px * var(--scale));
					flex: 0 0 33.33%;
					padding: 0 20px;
				}

				@media (min-width: 768px) and(max-width: 991px) {
					.process__grid li {
						flex: 0 0 50%;
					}
				}

				@media (max-width: 767px) {
					.process__grid li {
						flex: 0 0 100%;
					}
				}

				.process__grid li h3 {
					line-height: 1.25;
					letter-spacing: -1px;
					margin-bottom: calc(10px * var(--scale));
					font-weight: 400;
				}

				.process__grid li a,
				.process__grid li p {
					font-size: 1.25rem;
					line-height: 1.4;
					letter-spacing: -0.5px;
				}

				.process__grid li a {
					margin-top: calc(20px * var(--scale));
				}

				.hero {
					opacity: 1;
					transform: translateY(0);
					transition: all 1.2s ease;
					transition-delay: 0s;
					padding-top: calc(220px * var(--scale));
					padding-bottom: calc(100px * var(--scale));
				}

				@media (max-width: 767px) {
					.hero {
						padding-bottom: calc(150px * var(--scale));
					}
				}

				.hero .container h1 {
					font-weight: 400;
					font-size: 10rem;
					letter-spacing: -3px;
					line-height: 1.02;
				}

				@media (min-width: 992px) and(max-width: 1199px) {
					.hero .container h1 {
						font-size: 8.5rem;
					}
				}

				@media (min-width: 768px) and(max-width: 991px) {
					.hero .container h1 {
						font-size: 6.5rem;
					}
				}

				@media (max-width: 767px) {
					.hero .container h1 {
						font-size: 4.5rem;
					}
				}

				.hero .container h1 span {
					display: block;
				}

				.hero .container > p {
					padding-top: calc(100px * var(--scale));
					padding-bottom: calc(180px * var(--scale));
					padding-left: 34.3%;
					font-weight: 400;
					font-size: 3rem;
					letter-spacing: -1px;
					line-height: 1.18;
				}

				@media (min-width: 992px) and(max-width: 1199px) {
					.hero .container > p {
						padding-left: 15%;
					}
				}

				@media (min-width: 768px) and(max-width: 991px) {
					.hero .container > p {
						padding-top: calc(160px * var(--scale));
						padding-left: 10%;
						font-size: 2.25rem;
					}
				}

				@media (max-width: 767px) {
					.hero .container > p {
						padding-top: calc(160px * var(--scale));
						font-size: 1.5rem;
						padding-left: 0;
					}
				}

				.hero .container .approach {
					text-align: center;
				}

				.hero .container .approach h1 {
					font-size: 3rem;
					letter-spacing: -0.025em;
					line-height: 1.18;
					max-width: 1026px;
					margin-left: auto;
					margin-right: auto;
					margin-bottom: 2.5rem;
				}

				@media (min-width: 768px) and(max-width: 991px) {
					.hero .container .approach h1 {
						font-size: 2.25rem;
					}
				}

				@media (max-width: 767px) {
					.hero .container .approach h1 {
						font-size: 1.5rem;
					}
				}

				.hero .container .approach > a {
					font-size: 1.5rem;
					letter-spacing: -0.025em;
					line-height: 1.33;
				}

				.hero .container .approach ul {
					list-style: none;
					padding: 0;
					margin: 0;
					margin-top: calc(160px * var(--scale));
				}

				@media (min-width: 768px) {
					.hero .container .approach ul {
						display: flex;
						align-items: top;
						margin-left: -1.25rem;
						margin-right: -1.25rem;
					}
				}

				.hero .container .approach ul li {
					margin-bottom: calc(100px * var(--scale));
				}

				.approach ul li {
					justify-content: flex-end;
					display: flex;

					align-items: flex-start;
					flex-direction: column;
				}

				@media (min-width: 768px) {
					.hero .container .approach ul li {
						min-width: 50%;
						padding-left: 1.25rem;
						padding-right: 1.25rem;
					}
				}

				.hero .container .approach ul li h2 {
					font-size: 6.25rem;
					margin-bottom: 1.25rem;
					line-height: 1.08;
					letter-spacing: -0.04em;
				}

				@media (max-width: 1199px) {
					.hero .container .approach ul li h2 {
						font-size: 4.25rem;
					}
				}

				@media (max-width: 991px) {
					.hero .container .approach ul li h2 {
						font-size: 3.25rem;
					}
				}

				@media (max-width: 767px) {
					.hero .container .approach ul li h2 {
						font-size: 3rem;
					}
				}

				.hero .container .approach ul li h2 span {
					font-size: 3rem;
					vertical-align: baseline;
				}

				.hero .container .approach ul li p {
					max-width: calc(387px * var(--scale));
					margin: 0 auto;
					font-size: 1.25rem;
					line-height: 1.4;
					letter-spacing: -0.02em;
				}

				.hero.fade-out {
					opacity: 0;
					transform: translateY(-100px);
				}

				.team {
					color: #042825;
					padding-bottom: calc(200px * var(--scale));
				}

				.team .container .intro {
					padding-bottom: calc(80px * var(--scale));
				}

				.team .container .intro h2 {
					font-weight: 400;
					font-size: 3rem;
					letter-spacing: -1px;
					line-height: 1.16;
					max-width: 33.75rem;
					margin-bottom: 1.25rem;
				}

				@media (max-width: 767px) {
					.team .container .intro h2 {
						font-size: 2.375rem;
					}
				}

				.team .container .intro p {
					font-weight: 400;
					font-size: 1.25rem;
					letter-spacing: -0.5px;
					line-height: 1.4;
					max-width: 51.25rem;
					padding-bottom: 0;
				}

				.team .team-table {
					margin-bottom: calc(100px * var(--scale));
					display: block;
				}

				.team .team-table .team-member {
					border-bottom: 1px solid #042825;
					padding-top: 1.25rem;
					padding-bottom: 1.25rem;
					font-size: 1.5rem;
					line-height: 1.33;
					letter-spacing: -0.025em;
					display: flex;
					align-items: center;
					justify-content: space-between;
				}

				@media (min-width: 768px) {
					.team .team-table .team-member > div {
						flex: 1 0 0;
					}
				}

				.team .team-table .team-member > div:nth-child(2) {
					padding-left: 1.25rem;
					padding-right: 1.25rem;
				}

				@media (max-width: 767px) {
					.team .team-table .team-member > div:nth-child(2) {
						display: none;
					}
				}

				.team .team-table .team-member > div:last-child {
					text-align: right;
				}

				@media (min-width: 768px) and(max-width: 991px) {
					.team .team-table .team-member {
						font-size: 1.375rem;
					}
				}

				@media (max-width: 767px) {
					.team .team-table .team-member {
						font-size: 1.25rem;
					}
				}

				.team .team-table .team-member .team-photo-name {
					display: flex;
					align-items: center;
				}

				.team .team-table .team-member .role-mobile {
					display: block;
				}

				@media (min-width: 768px) {
					.team .team-table .team-member .role-mobile {
						display: none;
					}
				}

				.team .team-table .team-member picture {
					display: inline-block;
					margin-right: 1.25rem;
				}

				.team .team-table .team-member picture img {
					width: 3.75rem;
					height: 3.75rem;
					border-radius: 50%;
					-o-object-fit: cover;
					object-fit: cover;
					-o-object-position: center;
					object-position: center;
				}

				.team .team-table .team-member .from {
					display: inline-block;
					margin-right: 0.625rem;
				}

				@media (max-width: 767px) {
					.team .team-table .team-member .from {
						display: none;
					}
				}

				.team .team-table .team-member .flag {
					display: inline-block;
				}

				.team .team-table .team-member .flag img {
					width: 1.375rem;
					height: 0.9375rem;
					margin-right: 0.75rem;
				}

				@media (min-width: 1500px) {
					.team .team-table .team-member .flag img {
						width: 1.625rem;
						height: 1.125rem;
					}
				}

				.team-join {
					text-align: center;
				}

				.team-join p {
					font-size: 1.25rem;
					line-height: 1.4;
					letter-spacing: -0.02em;
					margin-bottom: 1.25rem;
				}

				.team-join a {
					font-size: 1.5rem;
					line-height: 1.33;
					letter-spacing: -0.025em;
				}

				.our-values {
					background: #e6eded;
					padding-top: calc(120px * var(--scale));
					padding-bottom: calc(220px * var(--scale));
				}

				@media (max-width: 991px) {
					.our-values {
						padding-bottom: calc(40px * var(--scale));
					}
				}

				.our-values .container > h2 {
					letter-spacing: -1px;
					max-width: calc(814px * var(--scale));
					margin-bottom: calc(120px * var(--scale));
				}

				@media (max-width: 767px) {
					.our-values .container > h2 {
						font-size: 2.375rem;
					}
				}

				.our-values .container > ul {
					margin: 0;
					padding: 0;
					list-style: none;
					display: flex;
					flex-wrap: wrap;
				}

				.our-values .container > ul li {
					width: 50%;
					padding-left: calc(60px * var(--scale));
					padding-right: calc(60px * var(--scale));
					padding-bottom: calc(80px * var(--scale));
					text-align: center;
				}

				@media (max-width: 991px) {
					.our-values .container > ul li {
						width: 100%;
						padding-top: 0 !important;
						padding-bottom: calc(120px * var(--scale));
					}
				}

				@media (max-width: 767px) {
					.our-values .container > ul li {
						padding-left: 0;
						padding-right: 0;
					}
				}

				.our-values .container > ul li .img-wrapper {
					margin: 0 auto;
				}

				@media (min-width: 1500px) {
					.our-values .container > ul li .img-wrapper {
						padding: 0;
					}
				}

				@media (min-width: 1200px) and(max-width: 1499px) {
					.our-values .container > ul li .img-wrapper {
						padding: 0 calc(55px * var(--scale));
					}
				}

				@media (min-width: 992px) and(max-width: 1199px) {
					.our-values .container > ul li .img-wrapper {
						padding: 0 calc(30px * var(--scale));
					}
				}

				.our-values .container > ul li .img-wrapper img,
				.our-values .container > ul li .img-wrapper picture {
					width: auto;
					max-width: 100%;
				}

				.our-values .container > ul li h2 {
					letter-spacing: -1px;
					padding: calc(60px * var(--scale)) 0
						calc(20px * var(--scale)) 0;
				}

				@media (max-width: 767px) {
					.our-values .container > ul li h2 {
						font-size: 2.375rem;
					}
				}

				.our-values .container > ul li p {
					font-size: 1.25rem;
					letter-spacing: -0.5px;
					line-height: 1.4;
				}

				.our-values .container > ul li:nth-child(2) {
					padding-top: calc(160px * var(--scale));
				}

				.our-values .container > ul li:nth-child(4) {
					padding-top: calc(80px * var(--scale));
				}

				.our-values .container .mission {
					text-align: center;
					padding-top: calc(120px * var(--scale));
				}

				.our-values .container .mission picture {
					display: block;
					margin-bottom: calc(160px * var(--scale));
				}

				.our-values .container .mission picture img {
					width: 100%;
					height: auto;
				}

				.our-values .container .mission h1 {
					font-weight: 400;
					letter-spacing: -0.04em;
					line-height: 1.11;
					max-width: calc(1026px * var(--scale));
					margin: 0 auto 1.25rem;
				}

				@media (min-width: 768px) and(max-width: 991px) {
					.our-values .container .mission h1 {
						font-size: 3.875rem;
					}
				}

				@media (max-width: 767px) {
					.our-values .container .mission h1 {
						font-size: 2.75rem;
					}
				}

				.our-values .container .mission p {
					font-weight: 400;
					font-size: 1.25rem;
					line-height: 1.4;
					letter-spacing: -0.02em;
					max-width: calc(600px * var(--scale));
					margin: 0 auto 2.5rem;
				}

				.our-values .container .mission a {
					font-size: 1.5rem;
					line-height: 1.33;
					letter-spacing: -0.02em;
				}

				@media (min-width: 768px) and(max-width: 991px) {
					.our-values .container .mission a {
						font-size: 1.375rem;
					}
				}

				@media (max-width: 767px) {
					.our-values .container .mission a {
						font-size: 1.25rem;
					}
				}

				.clients {
					padding-top: calc(140px * var(--scale));
					padding-bottom: calc(150px * var(--scale));
					color: #042825;
					overflow: hidden;
				}

				@media (max-width: 767px) {
					.clients {
						padding-top: calc(100px * var(--scale));
						padding-bottom: calc(50px * var(--scale));
					}
				}

				.clients .container .intro h2 {
					font-weight: 400;
					font-size: 3rem;
					letter-spacing: -1px;
					line-height: 1.16;
					max-width: 35rem;
					margin-bottom: 1.25rem;
				}

				@media (max-width: 767px) {
					.clients .container .intro h2 {
						font-size: 2.375rem;
					}
				}

				.clients .container .intro p {
					font-weight: 400;
					font-size: 1.25rem;
					letter-spacing: -0.5px;
					line-height: 1.4;
					max-width: 35rem;
					padding-bottom: calc(80px * var(--scale));
				}

				.dislocated {
					background-color: #e6eded;
					padding-top: calc(120px * var(--scale));
					padding-bottom: calc(200px * var(--scale));
					position: relative;
				}

				.dislocated:before {
					content: '';
					display: block;
					position: absolute;
					bottom: -1px;
					left: 0;
					right: 0;
					height: 1px;
					background: #fff;
					z-index: 2;
				}

				.dislocated-header {
					text-align: center;
					margin-bottom: calc(160px * var(--scale));
				}

				.dislocated-header h1 {
					margin-bottom: 1.25rem;
					font-weight: 400;
					letter-spacing: -0.04em;
					line-height: 1.11;
				}

				@media (min-width: 768px) and(max-width: 991px) {
					.dislocated-header h1 {
						font-size: 3.875rem;
					}
				}

				@media (max-width: 767px) {
					.dislocated-header h1 {
						font-size: 2.75rem;
					}
				}

				.dislocated-header p {
					font-weight: 400;
					font-size: 1.25rem;
					line-height: 1.4;
					letter-spacing: -0.02em;
					max-width: calc(600px * var(--scale));
					margin: 0 auto 2.5rem;
				}

				.dislocated-header a {
					font-size: 1.5rem;
					line-height: 1.33;
					letter-spacing: -0.02em;
				}

				@media (min-width: 768px) and(max-width: 991px) {
					.dislocated-header a {
						font-size: 1.375rem;
					}
				}

				@media (max-width: 767px) {
					.dislocated-header a {
						font-size: 1.25rem;
					}
				}

				@media (min-width: 768px) {
					.dislocated .row {
						display: flex;
						align-items: center;
						margin-left: -1.25rem;
						margin-right: -1.25rem;
					}
				}

				@media (min-width: 768px) {
					.dislocated .row > div {
						width: 33.33333%;
						padding-left: 1.25rem;
						padding-right: 1.25rem;
					}
				}

				@media (max-width: 767px) {
					.dislocated .row > div {
						max-width: 386px;
						margin-left: auto;
						margin-right: auto;
					}

					.dislocated .row > div:not(:last-child) {
						margin-bottom: 3.75rem;
					}
				}

				.dislocated .row > div picture {
					display: block;
					margin-bottom: 1.875rem;
				}

				.dislocated .row > div picture img {
					width: 100%;
					max-width: 100%;
					height: auto;
				}

				.dislocated .row > div p {
					font-weight: 400;
					font-size: 1.5rem;
					letter-spacing: -0.025em;
					line-height: 1.33;
				}

				@media (min-width: 768px) and(max-width: 991px) {
					.dislocated .row > div p {
						font-size: 1.375rem;
					}
				}

				@media (max-width: 767px) {
					.dislocated .row > div p {
						font-size: 1.25rem;
					}
				}

				.dislocated .row > div .link {
					padding-top: calc(20px * var(--scale));
					transition: opacity 0.2s ease-in-out;
				}

				.dislocated .row > div .link a {
					position: relative;
					display: inline-flex;
					align-items: center;
					width: auto;
					overflow: hidden;
					text-decoration: none;
				}

				.dislocated .row > div .link a:hover span:after {
					right: 0;
				}

				.dislocated .row > div .link a img {
					width: 16px;
					margin-right: 10px;
				}

				.dislocated .row > div .link a span {
					display: block;
					position: relative;
					font-size: 1.5rem;
					letter-spacing: -0.5px;
				}

				@media (min-width: 768px) and(max-width: 991px) {
					.dislocated .row > div .link a span {
						font-size: 1.375rem;
					}
				}

				@media (max-width: 767px) {
					.dislocated .row > div .link a span {
						font-size: 1.25rem;
					}
				}

				.dislocated .row > div .link a span:after,
				.dislocated .row > div .link a span:before {
					content: '';
					position: absolute;
					left: 0;
					bottom: 0;
					right: 0;
					height: 2px;
					background-color: rgba(4, 40, 37, 0.2);
				}

				.dislocated .row > div .link a span:after {
					right: 100%;
					background-color: #042825;
					transition: all 0.4s cubic-bezier(0.215, 0.61, 0.355, 1);
				}
			`}</style>

			<section className="hero" id="om-oss-hero">
				<motion.div
					className="container"
					initial={{ opacity: 0, y: 50 }}
					animate={{ opacity: 1, y: 0 }}
					exit={{ opacity: 0 }}
					transition={transition_long}
				>
					<h1>
						{/* <span>We are</span> */}
						<img
							src={gnk_logo_text}
							alt="Grønn Næringskompetanse logo"
							style={{
								height: '16.2rem',
								verticalAlign: 'text-bottom',
							}}
						/>{' '}
						oppsummert.
					</h1>
					<p>
						{' '}
						Grønn Næringskompetanse ble startet i 2011 som et
						rådgivningsselskap spesialisert på finansiering av all
						type forskning og utvikling.
						<br />
						<br />
						Sammen med den enkelte bedrift utvikles ideen, den
						finansieres, og ideen eller prosjektet kan følges helt
						fra start til ferdigstillese med gjennomføring og
						rapportering. Bakgrunnen for firmaet var gründeren
						Bjørnar Bjellands arbeid med FoU for flere arbeidsgivere
						og organisasjoner siden tidlig på 1990-tallet.
						<br />
						<br />
						Selskapet har vært bevisst på en slank modell der
						kompetanse og personell tas inn etter behov. Dette gjør
						at vi kan ta på oss både små og store oppdrag, og at
						selskapet ikke er sårbare for svingninger i markedet.
					</p>
					{/* <div className="image-grid">
						<div className="row left">
							<div className="col">
								<picture>
									<source srcSet="https://images.unsplash.com/photo-1638258581353-0ab658542e73?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3500&q=80 2x, https://images.unsplash.com/photo-1638258581353-0ab658542e73?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3500&q=80 1x" />
									<img src=" https://images.unsplash.com/photo-1464798429116-8e26f96b2e60?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2670&q=80" />
								</picture>
							</div>
						</div>
						<div className="row top">
							<div className="col col-6 col-sm-12 right">
								<picture>
									<source srcSet="https://images.unsplash.com/photo-1638215274200-1b8d88e72ec7?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3500&q=80 2x, https://images.unsplash.com/photo-1638215274200-1b8d88e72ec7?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3500&q=80 1x" />
									<img src="https://images.unsplash.com/photo-1638025773292-1ef8c1ec8c5f?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3830&q=80" />
								</picture>
							</div>
							<div className="col col-6 col-sm-12 left">
								<picture>
									<source srcSet="https://images.unsplash.com/photo-1638208761094-755b6b017229?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3500&q=80 2x, https://images.unsplash.com/photo-1638208761094-755b6b017229?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3500&q=80 1x" />
									<img src="https://images.unsplash.com/photo-1638208761094-755b6b017229?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3500&q=80" />
								</picture>
							</div>
						</div>
					</div> */}

					<section className="about-section" id="acta">
						<div className="container" id="">
							<h2 className="black-text">
								Siden oppstarten i 2011 har Grønn
								Næringskompetanse fått tilslag på, administrert
								og rapportert mer enn 80 prosjekter i tillegg
								til generell rådgivning for bedrifter. Selskapet
								har i dag to faste ansatte.
							</h2>

							<div
								className="stripe"
								style={{ marginBottom: '2rem' }}
							></div>

							<div
								className="row-about"
								style={{
									marginBottom: 'calc(120px * var(--scale))',
									backgroundColor: '',
									flexDirection: 'row-reverse',
								}}
								id=""
							>
								<div className="image">
									<picture>
										<StaticImage
											src="../assets/portraits/portrett_bjornar_2.jpg"
											alt="Portrett av ansatt Bjørnar Bjelland, med trær i bakgrunn."
											className="bordered-container"
										/>
									</picture>
								</div>
								<div
									className="text black-text"
									style={{ paddingLeft: 0 }}
								>
									<h2>Bjørnar Bjelland</h2>
									<p>
										<br />
										Finansiert og gjennomført mer en 80
										forsknings eller utviklingsprosjekter på
										vegne av kunder. Flere
										oppstartsbedrifter innen omsetning,
										fordeling og langtidslagring
										<br />
										<br />
										Arbeidet med oppbygging og utvikling av
										bærekraft og sirkulærøkonomi bla gjennom
										Reklima AS siden 2016
										<ul>
											<li>
												Tett samarbeid med
												forskningsinstitusjoner,
												forskningsråd, regionale
												forskningsfond, fylkeskommuner…
											</li>
										</ul>
										Utdanning:
										<ul>
											<li>
												NMBU, (Norges Miljø og
												Biovitenskapelige
												Universitet),sivilagronom innen
												hagebruk.
											</li>
											<li>
												BI: Innovation Management in a
												Global Business perspective.
											</li>
											<li>
												Gimlekollen Mediasenter:
												Radiojournalistikk
											</li>
											<li>
												Mer enn 30 års erfaring innen
												ideutvikling, oppstart, FoU
												arbeid, fasilitering og
												prosjektledelse.
											</li>
										</ul>
									</p>
								</div>
							</div>

							<div
								className="stripe"
								style={{ marginBottom: '2rem' }}
							></div>
							<div
								className="row-about"
								id=""
								style={{ backgroundColor: '' }}
							>
								<div className="image">
									<StaticImage
										src="../assets/portraits/portrett_lars_2.jpg"
										alt="Portrett av ansatt Lars Gundersen, med trær i bakgrunn."
										className="bordered-container"
									/>
								</div>
								<div className="text black-text">
									<h2>Lars Gundersen</h2>
									<p>
										<br />
										Lars brenner for tekst og formidling.
										Han har erfaring som journalist i NRK og
										informasjonsmedarbeider. I tillegg har
										han jobbet i Elektronisk
										Undervisningsforlag.
										<br />
										<br />
										Han elsker å samarbeide med kunder som
										et team, samt at han brenner sterkt for
										orden og estetikk, både til daglig, - og
										med prosjekter.
										<br />
										<br />
										Tolv år ute i Europa har gitt bred
										erfaring med internasjonalt samarbeid.
										<br />
										<br />
										Utdanning:
										<ul>
											<li>
												Journalistikk utdanning fra
												Gimlekollen Mediasenter.
											</li>
											<li>
												Statsvitenskap på Universitetet
												i Oslo.
											</li>
										</ul>
									</p>
								</div>
							</div>

							<h2 className="black-text">
								Sammen med våre samarbeidspartnere skreddersyr
								vi gode løsninger tilpasset den enkeltes behov!
							</h2>
						</div>
					</section>
					<style jsx>{`
						.video-large {
							position: fixed;
							top: 0;
							left: 0;
							bottom: 0;
							right: 0;
							background: #000;
							z-index: 9000000;
							padding: calc(46px * var(--scale));
						}
						.video-large,
						.video-large iframe {
							width: 100%;
							height: 100%;
						}
						.video-large .close {
							position: absolute;
							top: calc(20px * var(--scale));
							right: calc(20px * var(--scale));
							cursor: pointer;
							opacity: 0.8;
							transition: opacity 0.2s;
							background: none;
							border: none;
							padding: 0;
							margin: 0;
							outline: none !important;
						}
						.video-large .close:active,
						.video-large .close:focus,
						.video-large .close:hover {
							outline: none !important;
							opacity: 1;
						}
						.video-large .close i {
							position: relative;
							display: block;
							width: calc(24px * var(--scale));
							height: calc(24px * var(--scale));
						}
						.video-large .close i:after,
						.video-large .close i:before {
							content: '';
							top: 11px;
							left: 2px;
							position: absolute;
							width: 20px;
							height: 2px;
							transform: rotate(45deg);
							background-color: #fff;
						}
						.video-large .close i:after {
							transform: rotate(-45deg);
						}
						.intro {
							color: #546db0;
							opacity: 1;
							transform: translateY(0);

							padding-bottom: calc(100px * var(--scale));
							transition: all 1.2s ease;
							transition-delay: 0s;
						}
						.intro.fade-out {
							opacity: 0;
							transform: translateY(-100px);
						}
						.intro.static {
							background: #042825;
						}

						.intro .hero h1 {
							font-weight: 400;
							letter-spacing: -1px;
							line-height: 1.11;
							margin-bottom: 2.5rem;
							max-width: calc(1000px * var(--scale));
						}
						@media (max-width: 1199px) {
							.intro .hero h1 {
								font-size: 4.25rem;
								max-width: 100%;
							}
						}
						@media (max-width: 991px) {
							.intro .hero h1 {
								font-size: 3.25rem;
							}
						}
						@media (max-width: 767px) {
							.intro .hero h1 {
								font-size: 3rem;
							}
						}
						@media (max-width: 575px) {
							.intro .hero h1 br {
								display: none;
							}
						}
						.intro p {
							font-size: 1.5rem;
							line-height: 1.33;
							letter-spacing: -0.025em;
							max-width: calc(700px * var(--scale));
						}
						@media (min-width: 768px) and (max-width: 991px) {
							.intro p {
								font-size: 1.375rem;
							}
						}
						@media (max-width: 767px) {
							.intro p {
								font-size: 1.25rem;
							}
						}
						.intro .video {
							position: relative;
							height: 0;
							padding-top: 56.29228687%;
						}
						.intro .video .video-inner {
							position: absolute;
							top: 0;
							left: 0;
							width: 100%;
							height: 100%;
							cursor: pointer;
						}
						.intro .video .video-inner video {
							position: absolute;
							top: 0;
							left: 0;
							width: 100%;
							height: 100%;
							-o-object-fit: cover;
							object-fit: cover;
							-o-object-position: center;
							object-position: center;
							font-family: 'object-fit: cover; object-position: center';
						}
						.intro .video .video-inner .video-overlay {
							position: absolute;
							top: 0;
							left: 0;
							width: 100%;
							height: 100%;
							background: rgba(4, 40, 37, 0.25);
						}
						.intro .video .video-inner .video-play {
							z-index: 2;
						}
						.intro .video .video-inner .video-play,
						.intro .video .video-inner .video-play .circle {
							position: absolute;
							left: 50%;
							top: 50%;
							transform: translate(-50%, -50%);
							width: calc(120px * var(--scale));
							height: calc(120px * var(--scale));
						}
						.intro .video .video-inner .video-play .circle {
							border-radius: 50%;
							background: rgba(4, 40, 37, 0.7);
							-webkit-backdrop-filter: blur(8px);
							backdrop-filter: blur(8px);
							transform-origin: center center;
							transition: all 0.5s ease;
							transition-delay: 0s;
						}
						.intro .video .video-inner .video-play svg {
							position: absolute;
							left: 50%;
							top: 50%;
							transform: translate(-50%, -50%);
						}
						.intro .video .video-inner .video-play:active .circle,
						.intro .video .video-inner .video-play:focus .circle,
						.intro .video .video-inner .video-play:hover .circle {
							width: calc(124px * var(--scale));
							height: calc(124px * var(--scale));
							background: rgba(4, 40, 37, 0.9);
						}
						.intro .partners {
							margin: 0;
							padding: 0;
							list-style: none;
							display: flex;
							justify-content: space-between;
							align-items: center;
							width: 100%;
							padding-top: calc(120px * var(--scale));
						}
						@media (max-width: 767px) {
							.intro .partners {
								display: none;
							}
						}
						.intro .partners li {
							display: block;
						}
						@media (min-width: 1200px) {
							.intro .partners li {
								transition: all 0.8s
									cubic-bezier(0.165, 0.84, 0.44, 1);
								opacity: 0;
								transform: translateY(100px) translateZ(0);
							}
						}
						@media (min-width: 1200px) {
							.intro .partners li:nth-child(4n + 1) {
								transition-delay: 0.08s;
							}
							.intro .partners li:nth-child(4n + 2) {
								transition-delay: 0.165s;
							}
							.intro .partners li:nth-child(4n + 3) {
								transition-delay: 0.33s;
							}
							.intro .partners li:nth-child(4n + 4) {
								transition-delay: 0.495s;
							}
							.intro .partners li:nth-child(4n + 5) {
								transition-delay: 0.4s;
							}
						}
						@media (max-width: 767px) {
							.intro .partners li {
								margin: 20px;
							}
						}
						.intro .partners li.aos-animate {
							opacity: 1 !important;
							transform: translateY(0) translateZ(0) !important;
						}
						@media (min-width: 576px) {
							.intro .partners li:first-child img {
								height: calc(20px * var(--scale));
							}
						}
						@media (min-width: 576px) {
							.intro .partners li:nth-child(2) img {
								height: calc(48px * var(--scale));
							}
						}
						@media (min-width: 576px) {
							.intro .partners li:nth-child(3) img {
								height: calc(22px * var(--scale));
							}
						}
						@media (min-width: 576px) {
							.intro .partners li:nth-child(4) img {
								height: calc(40px * var(--scale));
							}
						}
						@media (min-width: 576px) {
							.intro .partners li:nth-child(5) img {
								height: calc(25px * var(--scale));
							}
						}
						.intro .about {
							padding-top: calc(200px * var(--scale));
							padding-bottom: calc(200px * var(--scale));
							padding-left: 26%;
						}
						@media (min-width: 992px) and (max-width: 1199px) {
							.intro .about {
								padding-left: 20%;
							}
						}
						@media (max-width: 991px) {
							.intro .about {
								padding-left: 0;
								padding-top: calc(120px * var(--scale));
								padding-bottom: calc(120px * var(--scale));
							}
						}
						.intro .about h2 {
							font-weight: 400;
							letter-spacing: -0.025em;
							line-height: 1.17;
						}
						@media (min-width: 992px) and (max-width: 1199px) {
							.intro .about h2 {
								font-size: 2.625rem;
							}
						}
						@media (max-width: 991px) {
							.intro .about h2 {
								font-size: 2.375rem;
							}
						}
						@media (max-width: 767px) {
							.intro .about h2 {
								font-size: 2rem;
							}
						}
						.intro .about h2 span {
							color: #2a2a2a;
						}
						.intro .about > a {
							font-size: 1.5rem;
							letter-spacing: -1px;
							line-height: 1.33;
						}
						.projects-list-title {
							padding-top: calc(120px * var(--scale));
							padding-bottom: calc(120px * var(--scale));
						}
						@media (max-width: 767px) {
							.projects-list-title {
								padding-top: calc(80px * var(--scale));
								padding-bottom: calc(80px * var(--scale));
							}
						}
						.projects-list-title .container h2 {
							font-weight: 400;
							color: #042825;
							letter-spacing: -1px;
							line-height: 1.15;
							margin-bottom: 1.25rem;
						}
						.projects-list-title .container h2 span {
							display: block;
						}
						@media (max-width: 767px) {
							.projects-list-title .container h2 {
								font-size: 2rem;
							}
						}
						.projects-list-title .container p {
							max-width: 490px;
							font-size: 1.25rem;
						}
						.clients-section .container {
							position: relative;
						}
						.clients-section .container:before {
							content: '';
							display: block;
							position: absolute;
							top: 0;
							left: calc(20px * var(--scale));
							right: calc(20px * var(--scale));
							height: 1px;
							background: #042825;
						}
						.clients-section .container > h2 {
							letter-spacing: -1px;
							line-height: 1.15;
							max-width: 600px;
							padding-top: calc(120px * var(--scale));
							margin-bottom: 1.25rem;
						}
						@media (max-width: 767px) {
							.clients-section .container > h2 {
								font-size: 2rem;
							}
						}
						.clients-section .container > p {
							max-width: 493px;
							padding-bottom: calc(80px * var(--scale));
							font-size: 1.25rem;
							line-height: 1.4;
							letter-spacing: -0.02em;
						}
						.insights-section {
							padding-bottom: calc(200px * var(--scale));
						}
						.insights-section .container {
							position: relative;
						}
						.insights-section .container:before {
							content: '';
							display: block;
							position: absolute;
							top: 0;
							left: calc(20px * var(--scale));
							right: calc(20px * var(--scale));
							height: 1px;
							background: #042825;
						}
						.about-section {
							position: relative;
							background: xc;
							color: #fff;
							padding-top: calc(120px * var(--scale));
						}
						.about-section .container > h1 {
							letter-spacing: -0.04em;
							line-height: 1.11;
							max-width: calc(850px * var(--scale));
							padding-bottom: calc(120px * var(--scale));
							text-align: center;
							margin: 0 auto;
						}
						.about-section .container > h2 {
							letter-spacing: -0.04em;
							line-height: 1.11;
							max-width: calc(850px * var(--scale));
							padding-bottom: calc(120px * var(--scale));
							text-align: center;
							margin: 0 auto;
						}

						@media (max-width: 1199px) {
							.about-section .container > h1 {
								font-size: 4.25rem;
							}
						}
						@media (max-width: 991px) {
							.about-section .container > h1 {
								font-size: 3.25rem;
							}
						}
						@media (max-width: 767px) {
							.about-section .container > h1 {
								font-size: 3rem;
							}
						}
						@media (max-width: 575px) {
							.about-section .container > h1 {
								font-size: 2.25rem;
							}
						}
						.about-section .row-about {
							margin-bottom: calc(120px * var(--scale));
						}
						@media (min-width: 768px) {
							.about-section .row-about {
								display: flex;
								align-items: center;
								margin-left: -1.25rem;
								margin-right: -1.25rem;
							}
						}
						.about-section .row-about .image {
							margin-bottom: 1.25rem;
						}
						@media (min-width: 768px) {
							.about-section .row-about .image {
								min-width: 50%;
								padding-left: 1.25rem;
								padding-right: 1.25rem;
								margin-bottom: 0;
							}
						}
						.about-section .row-about .image img,
						.about-section .row-about .image picture {
							display: block;
							max-width: 100%;
							width: 100%;
						}
						@media (min-width: 768px) {
							.about-section .row-about .text {
								min-width: 50%;
								padding-left: 1.25rem;
								padding-right: 1.25rem;
							}
						}
						@media (min-width: 992px) {
							.about-section .row-about .text {
								padding-left: calc(8.33333333% + 1.25rem);
							}
						}
						@media (max-width: 767px) {
							.about-section .row-about .text {
								padding-top: 20px;
							}
						}
						.about-section .row-faq {
							display: flex;
							flex-direction: column-reverse;
						}
						@media (min-width: 768px) {
							.about-section .row-faq {
								flex-direction: row;
								margin-left: -1.25rem;
								margin-right: -1.25rem;
							}
						}
						@media (min-width: 768px) {
							.about-section .row-faq .text {
								min-width: 50%;
								padding-left: 1.25rem;
								padding-right: 1.25rem;
							}
						}
						.about-section .row-faq .text h2 {
							margin-bottom: 1.25rem;
							letter-spacing: -0.025em;
							line-height: 1.18;
						}
						@media (min-width: 768px) and (max-width: 991px) {
							.about-section .row-faq .text h2 {
								font-size: 2.25rem;
							}
						}
						@media (max-width: 767px) {
							.about-section .row-faq .text h2 {
								font-size: 1.5rem;
							}
						}
						.about-section .row-faq .text p {
							max-width: 493px;
						}
						.about-section .row-faq .image {
							margin-bottom: 2.5rem;
							text-align: center;
						}
						@media (min-width: 768px) {
							.about-section .row-faq .image {
								min-width: 50%;
								padding-left: 1.25rem;
								padding-right: 1.25rem;
								margin-bottom: 0;
							}
						}
						@media (min-width: 992px) {
							.about-section .row-faq .image {
								padding-left: calc(8.33333333% + 1.25rem);
							}
						}
						@media (max-width: 767px) {
							.about-section .row-faq .image {
								padding-top: 20px;
							}
						}
						.about-section a,
						.about-section p {
							font-size: 1.5rem;
							line-height: 1.33;
							letter-spacing: -0.02em;
						}
						@media (min-width: 768px) and (max-width: 991px) {
							.about-section a,
							.about-section p {
								font-size: 1.375rem;
							}
						}
						@media (max-width: 767px) {
							.about-section a,
							.about-section p {
								font-size: 1.25rem;
							}
						}
					`}</style>
				</motion.div>
			</section>

			{/* <section className="our-values">
				<span />
				<div className="header">
					<div className="container">
						<h2>
							<img
								src={gnk_logo_text}
								alt="Logo - Grønn Næringskompetanse"
								style={{
									height: '5.2rem',
									verticalAlign: 'text-bottom',
								}}
							/>{' '}
							oppsumert — med mange års erfaring og flere hundre
							vellykket prosjekter, kan vi definitivt hjelpe deg
							med dine behov.
						</h2>

						<Partners />
					</div>
				</div>
				<span midpoint-offset={100} />
			</section> */}
		</Layout>
	);
};

export default About;
export const Head = () => (
	<>
		<title>Om oss — Grønn Næringskompetanse</title>

		<meta name="author" content="Bjørnar Bjelland" />
		<meta name="copyright" content="Grønn Næringskompetanse" />
		<meta name="robots" content="follow" />
		<meta name="language" content="Norwegian"></meta>
		<meta name="revisit-after" content="7 days"></meta>
		<meta
			name="description"
			content="Siden oppstarten i 2011 har vi fått tilslag på, administrert og rapportert mer enn 80 prosjekter. Selskapet har i dag to faste ansatte."
		/>
		<meta
			name="keywords"
			content="Om oss, informasjon, ansatte i firmaet, historien bak firmaet, milepæler"
		/>
	</>
);
